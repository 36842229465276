<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      @submit="handleSubmit(submit)"
      @close="$emit('close')"
      :loading="loadingData"
      submit="Save"
      :button-class="`${bgButton} text-white`"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          {{ title }}
        </p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold">Personal Information</p>
        <div
          class="grid grid-cols-12 gap-2 text-darkPurple"
          style="margin-top:1.125rem"
        >
          <div class="col-span-12">
            <c-text
              placeholder="--Enter--"
              variant="w-full h-10"
              :rules="['required']"
              label="First Name"
              v-model="userDetails.fname"
            />
          </div>
          <div class="col-span-12">
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              :rules="['required']"
              label="Last Name"
              v-model="userDetails.lname"
            />
          </div>
          <div v-if="userDetails.isEmployee" class="col-span-12">
            <div class="text-sm mb-1 text-jet">
              Work Email
            </div>
            <div
              class="wrapper disabled"
              v-tooltip.bottom-middle="userDetails.email"
            >
              {{ userDetails.email || email }}
            </div>
          </div>
          <div class="col-span-12" v-else>
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              label="Work Email"
              :rules="EmailRule"
              v-model="email"
            />
          </div>
          <div class="col-span-12 mb-4">
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              label="Phone"
              type="number"
              min="0"
              v-model="userDetails.phoneNo"
            />
          </div>
        </div>
      </template>
      <accordion>
        <template v-slot:title>
          <p class="font-semibold text-darkPurple">Employment Information</p>
        </template>
        <div>
          <checkbox
            label="This user is an employee"
            class="cursor-pointer"
            checkbox-size="height:16px; width:16px; margin-top:4px;"
            label-style="color:#333333"
            @change="handleIsEmployeeChange"
            v-model="userDetails.isEmployee"
          />
          <div
            class="grid grid-cols-12 gap-2 text-darkPurple"
            style="margin-top:1.125rem"
            v-if="userDetails.isEmployee || userDetails.isEmployee === 'true'"
          >
            <div class="col-span-12">
              <c-select
                :options="filteredFunctions"
                label="Function"
                :rules="['required']"
                variant="w-full"
                v-model="userDetails.functionId"
              />
            </div>
            <div class="col-span-12">
              <c-select
                variant="w-full"
                label="Designation"
                :options="setDesignation && setDesignation.designations"
                :disabled="!setDesignation"
                v-model="userDetails.designation"
              />
            </div>
            <div class="col-span-12">
              <div class="text-sm mb-1 text-jet">Job Level</div>
              <div class=" disabled">
                {{  setLevel ? setLevel.name : 'Automatically Loaded from Selected Designation' }}
          </div>
            </div>
            <div class="col-span-12">
              <label class="date-label flex justify-start gap-1">
            <span>1<sup>st</sup></span>
            <span>Line Reporting</span>
            </label>
            <div class="disabled">
            {{  setReportingTo ? `${setReportingTo.fname} ${setReportingTo.lname}` : 'Automatically Loaded from Selected Designation' }}
            </div>
            </div>
          </div>
        </div>
      </accordion>
      <template v-if="!userDetails.isRoot">
      <accordion>
        <template v-slot:title>
          <p class="font-semibold text-darkPurple">Roles & Privileges</p>
        </template>
        <p class="text-blueCrayola">
          You should only assign roles to account admins. Select a role that embodies the privileges you wish to grant the admin.
        </p>
        <div
          class="grid grid-cols-12 gap-2 text-darkPurple"
          style="margin-top:1.125rem"
        >
          <div class="col-span-12">
            <c-select
              variant="w-full"
              :options="roleSelect"
              label="Role"
              v-model="userDetails.roleId"
            />
          </div>
          <div
          class="grid grid-cols-12 gap-2 col-span-12 text-darkPurple"
          v-feature-request
          v-if="$store.state.subscription && $store.state.subscription.plan !== 'basic'"
        >
          <div class="flex col-span-12 my-3">
            <p class="text-sm text-blueCrayola">
              Apply Role To
            </p>
            <span>
              <Icon
                v-tooltip.top="
                  `Specify the scope and limitations of this user's role`
                "
                class-name="text-jet h-3 w-1 mt-1 flex cursor-pointer"
                icon-name="tooltip"
                size="xsm"
            /></span>
          </div>
          <div class="col-span-12">
            <label class="date-label">Location</label>
            <v-select
              :clearable="false"
              class="style-select"
              multiple
              :reduce="option => option.id"
              label="name"
              v-model="userDetails.permission.includedLocationIds"
              :options="locationData"
              :close-on-select="false"
            >
              <template #option="{name }">
                <div class="flex">
                  <div class="hover:text-white">
                    <div class="font-bold mt-1 text-sm cursor-pointer">
                      {{ name }}
                    </div>
                  </div>
                </div>
              </template>
              <template #selected-option="{name}">
                {{ name }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon
                    icon-name="caret-down"
                    style="margin-right:14px"
                    size="xsm"
                  />
                </span>
              </template>
            </v-select>
          </div>
          <div class="col-span-12">
            <label class="date-label">Function</label>
            <v-select
              :clearable="false"
              class="style-select"
              multiple
              :reduce="option => option.id"
              label="name"
              v-model="userDetails.permission.includedFunctionIds"
              :close-on-select="false"
              :options="filteredFunctions"
            >
              <template #option="{name }">
                <div class="flex">
                  <div class="hover:text-white">
                    <div class="font-bold mt-1 text-sm cursor-pointer">
                      {{ name }}
                    </div>
                  </div>
                </div>
              </template>
              <template #selected-option="{name}">
                {{ name }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon
                    icon-name="caret-down"
                    style="margin-right:14px"
                    size="xsm"
                  />
                </span>
              </template>
            </v-select>
          </div>
          <div class="col-span-12">
            <label class="date-label">Job Level</label>
            <v-select
              :clearable="false"
              class="style-select"
              multiple
              :reduce="option => option.id"
              label="name"
              v-model="userDetails.permission.includedLevelIds"
              :close-on-select="false"
              :options="levelData"
            >
              <template #option="{name }">
                <div class="flex">
                  <div class="hover:text-white">
                    <div class="font-bold mt-1 text-sm cursor-pointer">
                      {{ name }}
                    </div>
                  </div>
                </div>
              </template>
              <template #selected-option="{name}">
                {{ name }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon
                    icon-name="caret-down"
                    style="margin-right:14px"
                    size="xsm"
                  />
                </span>
              </template>
            </v-select>
          </div>
          <div class="col-span-12">
            <label class="date-label">Exceptions</label>
            <v-select
              :clearable="false"
              label="fname"
              multiple
              class="style-select"
              :reduce="option => option.id"
              v-model="userDetails.permission.excludedUserIds"
              @search="getEmployees"
              :options="employees"
              :filterable="false"
            >
              <template
                #option="{fname, lname, orgFunction, userDesignation, photo }"
              >
                <div class="flex">
                  <span class="mt-2 ">
                    <img
                      class=""
                      :src="photo"
                      v-if="photo"
                      alt="profile pic"
                      style="height: 25px; width: 25px; border-radius: 5px;"
                    />
                    <div
                      style="height: 25px; width: 25px; border-radius: 5px;"
                      class="text-blueCrayola text-xs border text-center font-semibold pt-1"
                      v-else
                    >
                      {{ $getInitials(`${fname} ${lname}`) }}
                    </div>
                  </span>
                  <div class="ml-2 hover:text-white">
                    <div class="font-bold mt-1 text-sm cursor-pointer">
                      {{ fname }} {{ lname }}
                    </div>
                    <div class=" " style="font-size: 10px">
                      <span class="uppercase">{{
                        orgFunction ? orgFunction.name : ""
                      }}</span>
                      <span class="ml-2">{{
                        userDesignation ? `${userDesignation.name}` : ""
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template #selected-option="{fname, lname}">
                {{ fname }} {{ lname }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon
                    style="margin-right:14px"
                    icon-name="caret-down"
                    size="xsm"
                  />
                </span>
              </template>
              <template #list-footer>
                <li style="text-align: center; font-size: 12px">
                  Type name of employee to search more
                </li>
              </template>
            </v-select>
          </div>
          </div>
        </div>
      </accordion>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { clone } from "lodash";
import { ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    ValidationObserver,
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    RightSideBar: () => import("@/components/RightSideBar"),
    Accordion: () => import("@/components/Accordion"),
    vSelect: () => import("vue-select"),
    Checkbox: () => import("@/components/Checkbox")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Create New Person"
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    from: {
      type: String,
      default: "people"
    },
    peopleData: {
      type: Object,
      default: () => {}
    },
    clearData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userDetails: {
        orgId: null,
        fname: null,
        lname: null,
        email: null,
        phoneNo: null,
        functionId: null,
        level: null,
        designation: null,
        roleId: null,
        reportingTo: null,
        isEmployee: false,
        permission: {
          includedLocationIds: null,
          includedFunctionIds: null,
          includedLevelIds: null,
          excludedUserIds: null
        }
      },

      isEmployee: false,
      bgButton: "bg-dynamicBackBtn",
      email: "",
      designationData: [],
      allOrgUsers: [],
      roleSelect: [],
      levelName: null,
      reportingName: null,
      loadingData: true,
      locationData: [],
      levelData: [],
      EmailRule: [
        "required",
        {
          name: "email",
          rule: v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        },
        {
          name: "available",
          rule: v => this.handleEmail(v)
        }
      ]
    };
  },
  methods: {
    queryStore(query) {
      if (this.userDetails.permission) {
        const {
          includedFunctionIds,
          includedLevelIds,
          includedLocationIds
        } = this.userDetails.permission;
        this.$store.dispatch(
          "employee/getEmployeesWithQueries",
          `functionId=${
            includedFunctionIds ? includedFunctionIds.toString() : ""
          }&levelId=${
            includedLevelIds ? includedLevelIds.toString() : ""
          }&locationIds=${
            includedLocationIds ? includedLocationIds.toString() : ""
          }&search=${query}`
        );
      }
    },
    getEmployees(search, loading) {
      loading(true);
      this.queryStore(search);
      loading(false);
    },
    getUserDetails() {
      this.loadingData = true;
      const { id } = this.peopleData;
      try {
        this.$_createOneUser({ userId: id }).then(response => {
          this.userDetails = response.data.user;
          this.email = this.userDetails.email;
          // this.populateLevel(this.userDetails.designation);
          if (this.userDetails.permission) {
            this.queryStore("");
          } else {
            this.userDetails.permission = {
              includedLocationIds: null,
              includedFunctionIds: null,
              includedLevelIds: null,
              excludedUserIds: null
            };
          }
          if (this.userDetails.roles.humanar) {
            this.userDetails.roleId = response.data.user.roles.humanar.roleId;
          }
          this.loadingData = false;
          this.userId = id;
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    submit() {
      if(this.from === 'admin' && !this.userDetails.isRoot && !this.userDetails.roleId){
        this.$toasted.error('You should assign roles to account admins', {duration: 6000})
          return
      }
      this.userDetails.orgId = this.$orgId;
      this.userDetails.email = this.email;
      this.userDetails.containsBio = true;
      this.userDetails.role = {
        appSlug: "humanar",
        roleId: this.userDetails.roleId
      };
      this.$emit("submit", this.userDetails);
    },
    async handleEmail(value) {
      const result = await this.$_checkEmailExist(value);
      if (!this.isEdit) {
        if (result.data.emailExists) {
          return "Email already exists";
        }
        return true;
      }
      return true;
    },
    async getDesignations() {
      try {
        const response = await this.$_getDesignations("");
        this.designationData = clone(response.data.designations);
      } catch (error) {
        throw new Error(error);
      }
    },
    async getRoles() {
      try {
        const response = await this.$_NewGetOrgRoles("");
        const orgRoles = response.data.roles;
        this.roleSelect = orgRoles.map(v => ({ id: v.id, name: v.name }));
      } catch (err) {
        throw new Error(err);
      }
    },
    async getLevels() {
      try {
        const response = await this.$_getLevels();
        const { levels } = response.data;

        this.levelData = levels;

        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    // populateLevel(value) {
    //   // eslint-disable-next-line no-console
    //   console.log('hhhiiiii')
    //   const result = this.designationData.find(element => element.id === value);
    //   this.userDetails.level = result ? result.level.id : null;
    //   this.levelName = result ? result.level.name : "Automatically Loaded from Selected Designation";
    //   let findReportingTo = null; let response
    //   if (this.setDesignation && this.setDesignation.length) {
    //     response = this.setDesignation.find(
    //       (item) => item.id === value
    //       );
    //       if (response) {
    //         this.filteredFunctions.forEach(element => {
    //           const foundDesignation = element.designations.find(item => item.id === response.reportingTo);
    //           if (foundDesignation) {
    //         findReportingTo = foundDesignation;
    //       }
    //     });

    //   }
    //   }
    //   this.reportingName = findReportingTo ? findReportingTo.hasUser : null
    //   this.userDetails.reportingTo = findReportingTo ? findReportingTo.hasUser.id : null
    // },
    handleIsEmployeeChange() {
      this.userDetails.functionId = null;
      this.userDetails.designation = null;
      this.userDetails.reportingTo = null;
      this.levelName = "";
    },
    changeReportingTo(id) {
      this.userDetails.reportingTo = id
    },
    changeLevel(id) {
      this.userDetails.level = id
    }
  },
  computed: {
    ...mapState({
      employees: state => state.employee.employees
    }),
    filteredFunctions() {
      return this.$store.state.org.getAllFunctions;
    },
    setDesignation() {
      const customiseDesignation = this.$store.state.org.getAllFunctions.find(
        item => item.id === this.userDetails.functionId
      );
      if (customiseDesignation) {
        customiseDesignation.designations.forEach((element, index )=> {
          if (element.isSupervisory && element.hasUser) {
            customiseDesignation.designations[index] = { ...element, disabled: true }
          }
          else {
            customiseDesignation.designations[index] = { ...element, disabled: false }
          }
        });
      }
      return customiseDesignation
    },
    setLevel() {
      let result = null;
      if (this.setDesignation) {
        result = this.setDesignation.designations.find(
          (item) => item.id === this.userDetails.designation
        );
      }
      this.changeLevel(result?.level?.id || null)
      return result?.level || null;
    },
    setReportingTo() {
      let findReportingTo = null; let result
      if (this.setDesignation) {
        result = this.setDesignation.designations.find(
          (item) => item.id === this.userDetails.designation
          );
          if (result) {
            this.filteredFunctions.forEach(element => {
              const foundDesignation = element.designations.find(item => item.id === result.reportingTo);
              if (foundDesignation) {
            findReportingTo = foundDesignation;
          }
        });
        if (findReportingTo?.hasUser) {
          const { id } = findReportingTo.hasUser
          this.changeReportingTo(id)
        }

      }
      }
    return findReportingTo ? findReportingTo.hasUser : null
    }
  },
  watch: {
    clearData() {
      this.userDetails = {
        orgId: null,
        fname: null,
        lname: null,
        email: null,
        phoneNo: null,
        functionId: null,
        level: null,
        designation: null,
        roleId: null,
        reportingTo: null,
        isEmployee: false,
        permission: {
          includedLocationIds: null,
          includedFunctionIds: null,
          includedLevelIds: null,
          excludedUserIds: null
        }
      };
      this.email = "";
      this.levelName = "";
    },
    peopleData() {
      if (this.peopleData) {
        this.getUserDetails();
      }
    },
    async openModal() {
      if (this.openModal) {
        await this.$store.dispatch("org/getFunction");
        await this.getDesignations();
        await this.getRoles();
        await this.getLevels();
        const result = await this.$_getHumanarLocations();
        this.locationData = result.data.outlets;
        this.loadingData = false;
      }
    }
  }
};
</script>

<style>
.tooltip {
  width: 393px;
}
</style>
